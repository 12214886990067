import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import bCorp from '../../assets/images/b-corp-logo.png';
import { ScImageWrapper, ScSchemeInfoWrapper, ScTextWrapper } from './styled';
import { ScTitle } from '../../components/Title/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { MotionDiv, MotionImage } from '../../animations/components';
import isoLogo from '../../assets/images/iso-logo.png';

export const SchemeInfo = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScContainer>
			<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
				<ScSchemeInfoWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScTextWrapper>
							<ScTitle>
								Save and spread the cost with Gogeta Home
							</ScTitle>
							<ScParagraph>
								<p>
									Gogeta Home saves employees money by letting them pay for big home buys such as  sofas or beds out of their gross pay, saving on National Insurance Contributions. They can also spread the cost over 12 months, which makes it even more affordable to cover those big home purchases.
								</p>
							</ScParagraph>
						</ScTextWrapper>
					</MotionDiv>

					<ScImageWrapper>
						<MotionImage src={isoLogo} alt="iso-logo" variants={fadeInRight} />
						<MotionImage src={bCorp} alt="b-corp" variants={fadeInLeft} />
					</ScImageWrapper>
				</ScSchemeInfoWrapper>
			</motion.section>
		</ScContainer>
	);
};
