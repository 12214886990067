import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import img from '../../assets/images/chair.png';
import hands from '../../assets/images/hands.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';

const items: IBenefitsCard[] = [
	{
		title: 'Spend and save',
		text: 'Moving house? Or just fancy a new sofa to binge watch your favourite show on? Whatever you need, you can save up to 8% with Gogeta Home. And you can spread the cost over 12 months, which makes those big home purchases even more affordable.',
		image: img,
		link: '/employee-home/',
		buttonText: 'Take me to the employee page',
	},
	{
		title: 'An extra slice for employees, at no cost to you',
		text: "Gogeta Home let's employee pay for the things they need (or just want) out of their gross pay, saving on National Insurance Contributions and spreading the cost over 12 months. It's simple to offer and doesn't cost you a penny.",
		image: hands,
		link: '/employer-home/',
		buttonText: 'Take me to the employer page',
	},
];

export const Benefits = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsContainer>
			<Top className="top" />
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
								buttonText={buttonText}
								inverted={index === 1}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
			<Bot />
		</ScBenefitsContainer>
	);
};
