import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { HomeCalculator } from '../../components/Calculators/home-calculator';
import { ScContainer } from '../../components/container/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScCalculatorWrapper } from './styled';
import {IBikeCalculatorValues} from "../../helpers/bike-calculations";

interface ITechCalculatorSection {
  setCalculatorData: (value: IBikeCalculatorValues) => void;
	enableBottomSection?: boolean;
}

export const HomeCalculatorSection = ({setCalculatorData}: ITechCalculatorSection) => {
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScCalculatorWrapper>
				<ScContainer>
					<MotionDiv variants={fadeInUp}>
						<ScTitle ref={ref} centered>
							{'See the savings'}
						</ScTitle>
					</MotionDiv>
					<HomeCalculator setCalculatorData={setCalculatorData}/>
				</ScContainer>
			</ScCalculatorWrapper>
		</motion.section>
	);
};
