import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { TypeOfScheme } from '@utils';

import ikea from '../assets/brands/ikea.png';
import pdf from '../assets/guides/Gogeta Bike - Employer Guide.pdf';
import { ReactComponent as Bot } from '../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../assets/icons/top-wave.svg';
import hangs from '../assets/images/2-hands.png';
import { AvailableAt } from '../components/AvailableAt/AvailableAt';
import { BrownInfo } from '../components/BrownInfoCard/BrownInfo';
import { CalculatorHowDoesThatWork } from '../components/CalculatorHowDoesThatWork/calculator-how-does-that-work';
import { ScWave } from '../components/Calculators/styled';
import { CustomButton } from '../components/custom-button';
import { ScPage } from '../components/page/styled';
import { SEO } from '../components/Seo/Seo';
import { SignUp } from '../components/SignUpCard/SignUp';
import { IBikeCalculatorValues } from '../helpers/bike-calculations';
import { MainLayout } from '../layouts/main-layout';
import { InfoSection } from '../sections/EmployeeCycling/InfoSection';
import { Benefits } from '../sections/EmployerHome/Benefits';
import { Hero } from '../sections/EmployerHome/Hero';
import { HomeCalculatorSection } from '../sections/EmployerHome/HomeCalculatorSection';
import { HowItWorks } from '../sections/EmployerHome/HowItWorks';
import { download } from '../utils/download';

const ErHomeScheme = () => {
	const [calculatorData, setCalculatorData] = React.useState<IBikeCalculatorValues>({
		platformFee: 0,
		totalSalSac: 0,
		monthlyTakeHomePay: 0,
		taxFreePrice: 0,
		totalSavings: 0,
		totalSavingsPercentage: 0,
		netFee: 0,
		salary: 0,
		voucherValue: 0,
	});

	return (
		<MainLayout>
			<ScPage>
				<Hero />
				<AvailableAt images={[ikea]} largeImage />
				<HomeCalculatorSection setCalculatorData={setCalculatorData} />
				<ScWave>
					<Top className="top" />
				</ScWave>
				<CalculatorHowDoesThatWork calculatorData={calculatorData} schemeType={TypeOfScheme.HOME} />
				<Benefits />
				<HowItWorks />
				<SignUp typeOfScheme={TypeOfScheme.HOME} />
				<ScWave>
					<Bot className="bottom" />
				</ScWave>
				<BrownInfo
					title="Get in touch"
					text="We’d love to chat. If you’re an employer we can give you a quick walkthrough of how it all works, and how Gogeta can benefit your employees."
					image={hangs}
					link={'https://meetings-eu1.hubspot.com/barry2/c2w'}
					typeOfScheme={TypeOfScheme.HOME}
					isBushRose={true}
				/>
				<InfoSection
					title="Want to find out more?"
					text="Browse our product guides for a bit more in depth info"
					buttons={
						<>
							<CustomButton
								onClick={() => {
									download(pdf as string);
								}}
							>
								Download the guide to Gogeta Home
							</CustomButton>
						</>
					}
				/>
				<ScInfoSection>
					<InfoSection
						title="FAQs"
						text="Got more questions? We’ve got you covered."
						buttons={
							<Link to="/employer-faq#tech-and-home-faqs">
								<CustomButton>Get some answers</CustomButton>
							</Link>
						}
					/>
				</ScInfoSection>
			</ScPage>
		</MainLayout>
	);
};

export default ErHomeScheme;

export const Head = () => (
	<SEO
		title="Gogeta Home for Employers | Cost-Free Employee Benefits"
		description="Offer Gogeta Home and help employees save on tech via salary sacrifice. They pay less, spread costs, and you provide a valued benefit at no extra cost."
	/>
);

export const ScInfoSection = styled.div`
	margin-top: 0;
	@media (min-width: 1220px) {
		.info-section-wrapper {
			margin-top: 100px;
		}
	}
`;
