import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/labtop_bread_right.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScLargeTitle } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import {
	ScCycleToWorkSchemeHeroButtons,
	ScCycleToWorkSchemeHeroWrapper,
	ScTitleWrapper,
} from './styled';

export const Hero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.toasted} disableHeight={true}>
				<ScContainer>
					<ScCycleToWorkSchemeHeroWrapper>
						<ScTitleWrapper>
							<MotionImage variants={fadeInRight} src={image} alt="bread" />
							<MotionDiv variants={fadeInLeft}>
								<ScLargeTitle className="hero-title" centered color={theme.colors.darkText}>
									Save on
									<br />
									new Tech
								</ScLargeTitle>
							</MotionDiv>
						</ScTitleWrapper>

						<MotionDiv variants={fadeInUp} className="buttons">
							<ScCycleToWorkSchemeHeroButtons>
								<Link to="/employee-tech/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										I'm an employee
									</CustomButton>
								</Link>
								<Link to="/employer-tech/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										I'm an employer
									</CustomButton>
								</Link>
							</ScCycleToWorkSchemeHeroButtons>
						</MotionDiv>
					</ScCycleToWorkSchemeHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
