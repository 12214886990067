import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/chair.png';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import Modal from '../../components/modal';
import { ScMainText } from '../../components/Text/styled';
import { ScTitleMedium } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScCycleToWorkSchemeHeroButtons } from '../TechScheme/styled';

import { ScCycleToWorkSchemeHeroWrapper, ScTitleWrapper } from './styled';
import {isNotSSR} from "@utils";

export const Hero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();
	const [isOpenModal, setIsOpenModal] = React.useState(false);

	const handleClick = () => {
		if (isNotSSR) {
			window.location.href = `${process.env.GATSBY_TECH_WEBSITE}/portal/home/employer_portal/register`;
		}
	}

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.peach} disableHeight={true}>
				{isOpenModal && (
					<Modal open={isOpenModal} title={'Contact us'} handleClose={() => setIsOpenModal(false)}>
						<ContactUsForm />
					</Modal>
				)}
				<ScContainer>
					<ScCycleToWorkSchemeHeroWrapper>
						<ScTitleWrapper className="employer-hero">
							<MotionDiv className="image" variants={fadeInRight}>
								<img src={image} alt="bread" />
							</MotionDiv>
							<MotionDiv variants={fadeInLeft} className="text-wrapper">
								<ScTitleMedium className="hero-title" color={theme.colors.darkText}>
									Help employees save on home buys
								</ScTitleMedium>
								<ScMainText isMobileLarge>
									Gogeta Home saves employees money on big home purchases and there is no cost to
									your business.
								</ScMainText>
								<ScCycleToWorkSchemeHeroButtons className={'buttons'}>
									<CustomButton
										color={theme.colors.darkText}
										background={theme.colors.wheat}
										onClick={() => {
											setIsOpenModal(true);
										}}
									>
										Get in touch
									</CustomButton>
									<CustomButton onClick={handleClick} color={theme.colors.darkText} background={theme.colors.wheat}>
										Sign up
									</CustomButton>
								</ScCycleToWorkSchemeHeroButtons>
							</MotionDiv>
						</ScTitleWrapper>
					</ScCycleToWorkSchemeHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
