import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import homeDough from '../../assets/images/home_dough.png';
import hangs from '../../assets/images/2-hands.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';
const items: IBenefitsCard[] = [
	{
		title: 'Where can employees spend?',
		text: 'Employees can buy their Home items at Ikea. They can save on big home purchases, such as a new sofa, bed or dining table and also spread the cost over 12 months.',
		image: homeDough,
	},
	{
		title: 'Is there a cost to the employer?',
		text: "No, there is no cost to the employer to offer Gogeta Home to staff and there's no minimum company size. It's incredibly simple to use Gogeta Home, employees simply apply online and then we issue their voucher for them to spend in-store or online.",
		image: hangs,
	},
];
export const Benefits = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsContainer>
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
								buttonText={buttonText}
								inverted={index % 2 === 0}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
		</ScBenefitsContainer>
	);
};
