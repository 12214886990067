import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import {ScTitle, ScTitleSmall} from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScInfoButtons, ScInfoSectionWrapper } from './styled';

interface IInfoSection {
	title: string;
	text: string;
	buttons?: JSX.Element | JSX.Element[];
	isSmallText?: boolean;
}

export const InfoSection = ({ title, text, buttons, isSmallText }: IInfoSection) => {
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScInfoSectionWrapper className={'info-section-wrapper'} isSmallText={isSmallText}>
					<MotionDiv variants={fadeInUp}>
						{isSmallText ?
							<ScTitleSmall ref={ref} centered dangerouslySetInnerHTML={{ __html: title }} /> :
							<ScTitle ref={ref} centered dangerouslySetInnerHTML={{ __html: title }} />
						}
					</MotionDiv>
					<MotionDiv variants={fadeInUp}>
						{isSmallText ?
							<ScParagraph centered dangerouslySetInnerHTML={{ __html: text }}></ScParagraph> :
							<ScParagraph centered dangerouslySetInnerHTML={{ __html: text }}></ScParagraph>
						}
					</MotionDiv>
					{buttons && <ScInfoButtons variants={fadeInUp}>{buttons}</ScInfoButtons>}
				</ScInfoSectionWrapper>
			</ScContainer>
		</motion.section>
	);
};
