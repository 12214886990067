import styled from 'styled-components';

export const ScCycleToWorkSchemeHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 0 67px;
	box-sizing: border-box;
	gap: 20px;
	justify-content: space-between;
	height: 100%;

	@media (min-height: 660px) and (min-width: 400px) {
		height: calc(100svh - 65px);
	}

	@media (min-width: 768px) {
		height: 100%;
		padding: 26px 0 92px;
		gap: 45px;
		@media (min-height: 1000px) {
			height: calc(100svh - 95px);
		}
	}

	@media (min-width: 1024px) and (min-height: 600px) {
		height: calc(100vh - 95px);
	}

	@media (min-width: 1220px) and (min-height: 600px) {
		height: calc(100vh - 120px);
	}

	@media (min-width: 1220px) {
		padding-top: 30px;
		gap: 20px;
	}

	.buttons {
		@media (min-width: 768px) {
			z-index: 10;
			margin-top: auto;
		}
	}
`;

export const ScTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	text-align: center;
	height: 100%;

	div {
		max-width: 800px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		gap: 25px;

		.buttons {
			gap: 10px;
		}
	}

	.text-wrapper {
		height: 100%;
	}

	.buttons {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: auto;
	}

	img {
		max-height: 400px;
		width: 300px;
	}

	.image {
		align-items: center;
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1.4fr;
		align-items: center;
		gap: 90px;
		justify-items: center;
		text-align: left;

		img {
			width: 100%;
			@media (min-height: 700px) {
				max-width: 100%;
				max-height: 530px;
			}

			@media (min-height: 800px) {
				max-height: 550px;
			}

			@media (min-height: 1020px) {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.buttons {
			justify-content: flex-start;
			margin-top: 0;
		}

		.text-wrapper {
			height: auto;
		}
	}

	@media (min-width: 1220px) {
		.employer-hero {
			grid-template-columns: 1fr 1.06fr;
		}

		.hero-title {
			font: ${({ theme }) => theme.fonts.heading.large};
		}
	}

	@media (min-width: 1240px) {
		.hero-title {
			font: ${({ theme }) => theme.fonts.heading.enormous};
		}
	}

	@media (min-width: 1440px) {
		.hero-title {
			font: ${({ theme }) => theme.fonts.heading.xl};
		}
	}
`;

export const ScCycleToWorkSchemeHeroButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	button {
		width: 220px;
		height: 66px;
		font: ${({ theme }) => theme.fonts.button.mediumPlus};
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const ScSchemeInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px 0px 60px;
	gap: 60px;
`;

export const ScTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	max-width: 900px;

	h2 {
		font: ${({ theme }) => theme.fonts.heading.mediumLarge};
	}

	p {
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
	}

	@media (min-width: 768px) {
		h2 {
			font: ${({ theme }) => theme.fonts.heading.largeSpaced};
		}

		p {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}
	}
`;

export const ScImageWrapper = styled.div`
	display: flex;
	height: 112px;
	column-gap: 60px;

	img {
		height: 100%;
		object-fit: cover;
	}

	@media (min-width: 768px) {
		height: 157px;
	}
`;

export const ScCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 1000px;
	padding: 40px;
	border-radius: 30px;
	background-color: #ffffff;

	p {
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
	}

	b {
		font: ${({ theme }) => theme.fonts.tell.smallText};
		font-family: 'GeomanistMedium';
	}

	@media (min-width: 768px) {
		p {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}

		b {
			font: ${({ theme }) => theme.fonts.tell.smallText};
			font-family: 'GeomanistMedium';
		}
	}
`;

export const ScBenefitsContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	overflow: hidden;
`;

export const ScBenefitsWrapper = styled.div`
	background: ${({ theme }) => theme.colors.white};
	padding: 20px 0;
`;

export const ScCalculatorWrapper = styled.div`
	section {
		width: 100%;
	}

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		padding: 0;
		margin: 80px 0 32px;
	}

	h2 {
		font: ${({ theme }) => theme.fonts.heading.mediumSpaced};
	}

	@media (min-width: 768px) {
		h2 {
			font: ${({ theme }) => theme.fonts.heading.largeSpaced};
		}

		> div {
			gap: 40px;
			margin: 50px 0 100px;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;
