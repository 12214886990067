import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/chair.png';
import { ScContainer } from '../../components/container/styled';
import { ScTitleMedium } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import {
	ScCycleToWorkSchemeHeroWrapper,
	ScTitleWrapper,
} from './styled';
import {ScMainText} from "../../components/Text/styled";

export const Hero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.peach} disableHeight={true}>
				<ScContainer>
					<ScCycleToWorkSchemeHeroWrapper>
						<ScTitleWrapper>
							<MotionDiv className="image" variants={fadeInRight}>
								<img src={image} alt="bread" />
							</MotionDiv>
							<MotionDiv variants={fadeInLeft}>
								<ScTitleMedium className="hero-title" color={theme.colors.darkText}>
									Sofa, so good
								</ScTitleMedium>
								<ScMainText>
									Save up to 8% on a new sofa (or any other big home buy) and pay interest-free over 12 months.
								</ScMainText>
							</MotionDiv>
						</ScTitleWrapper>
					</ScCycleToWorkSchemeHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
