import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import gogetaLaptop from '../../assets/images/gogeta-laptop.png';
import hangs from '../../assets/images/2-hands.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';
import { isNotSSR } from '@utils';

const items: IBenefitsCard[] = [
	{
		title: 'Where can I buy my tech?',
		text: 'You can buy your tech at either Currys or Apple (or both!). You can save on a huge variety of home and personal tech, from fridges and washing machines to earpods and air tags, from all the best brands, including Apple, Samsung, Dyson and Sony.',
		image: gogetaLaptop,
	},
	{
		title: 'How do I sign up?',
		text: "To use Gogeta Tech and save up to 8% on home and personal tech purchases, your employer needs to sign up. It's simple, quick and doesn't cost them a penny. Follow the link at the bottom of this page to get going.",
		image: hangs,
		onClick: () => {
      if (isNotSSR) {
        window.location.href = `${process.env.GATSBY_TECH_WEBSITE}/portal/employer_portal/register`;
      }
    },
		buttonText: 'Let’s get started',
	},
];
export const Benefits = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsContainer>
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText, onClick }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
                onClick={onClick}
								buttonText={buttonText}
								inverted={index % 2 === 0}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
		</ScBenefitsContainer>
	);
};
