import React from 'react';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { Hero } from '../sections/HomeScheme/Hero';
import { SchemeInfo } from '../sections/HomeScheme/SchemeInfo';
import { Benefits } from '../sections/HomeScheme/Benefits';
import { HomeCalculatorSection } from '../sections/HomeScheme/HomeCalculatorSection';
import {SEO} from "../components/Seo/Seo";

const ProviderHome = () => {
	return (
		<MainLayout>
			<ScPage>
				<Hero />
				<SchemeInfo />
				<Benefits />
				<HomeCalculatorSection />
			</ScPage>
		</MainLayout>
	);
};

export default ProviderHome;

export const Head = () => (
	<SEO
		title="Gogeta Home: Affordable Devices & Flexible Payments for All"
		description="Discover Gogeta Home: save on devices by paying from gross pay, reduce NI costs, and spread payments over 12 months. Ideal for employees and employers."
	/>
)
